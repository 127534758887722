<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        How much time is required to electroplate
        <number-value :value="mass" unit="\text{mg}" />
        of chromium from a chromium(III) nitrate solution using a current of
        <number-value :value="current" unit="\text{A}" />
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="$\text{time: }$"
        append-text="$\text{s}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemCPP1220E3_Question8',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
    current() {
      return this.taskState.getValueBySymbol('current').content;
    },
  },
};
</script>
